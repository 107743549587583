@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400&display=swap');

body {
  font-family: 'Manrope', Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #EDF0F4;
  position: fixed;
  width: 100vw;
}


.App {
  text-align: center;
}

.chat-container {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full viewport height */
  max-width: 100vw;
  background-color: #EDF0F4;
  overflow: hidden; /* Prevent body scroll */
}

.chat-header {
  padding: 15px;
  border-bottom: 1px solid #ddd;
  text-align: center;
  background-color: #EDF0F4;
  position: fixed; /* Fix header to the top */
  top: 0;
  left: 0;
  right: 0;
  z-index: 10; /* Ensure it stays on top */
  height: 60px; /* Default height */
}

.chat-header h1 {
  position: absolute;
  color: #5A665D;
  margin: 0;
  right: 15px;
  top: 20px;
  height: 50px;
  width: auto;
}

.chat-header img {
  position: absolute;
  left: 0px;
  top: 10px;
  height: 60px;
  width: auto;
}

.chat-main {
  flex: 1;
  padding: 10px;
  margin-top: 100px; /* Adjust for header height */
  margin-bottom: 110px; /* Adjust for footer height */
  overflow-y: auto; /* Allow scrolling for chat messages */
  background-color: #EDF0F4;

}

/* Scrollbar styles */
::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Scrollbar track color */
}

::-webkit-scrollbar-thumb {
  background: #888; /* Scrollbar thumb (scroll handle) color */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Scrollbar thumb color on hover */
}

.chat-message-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.chat-message-container.user {
  justify-content: flex-end;
}

.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 5px;
}

.profile img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-bottom: 5px;
}

.chat-message {
  max-width: 70%;
  padding: 0px 12px;
  border-radius: 15px;
  font-size: 16px;
  
  text-align: left;
  word-break: break-word;
  position: relative;
  margin-left: 10px;
}

.chat-message.user {
  background-color: #FAF9F6;
  color: black;
  border-radius: 15px 15px 0 15px;
}

.chat-message.AI {
  background-color: #E0E9EC;
  color: black;
  border-radius: 15px 15px 15px 0;
}

.ai-message-arrow {
  position: absolute;
  top: 10px;
  left: -10px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #f1f1f1;
}

.chat-footer {
  padding: 10px;
  background-color: #EDF0F4;
  position: fixed; /* Fix footer to the bottom */
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10; /* Ensure footer stays on top */
  border-top: 1px solid #ddd;
  /* height: 90px; */
  display: flex;
  flex-direction: column;
}

.footer-container {
  position: relative;
  width: 100%;
}

.footer-textarea {
  font-family: 'Manrope', Arial, sans-serif;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  font-size: 16px;
  border-radius: 10px;
  background-color: #d9d9d9;
  resize: none;  
  overflow-y: auto;  
  min-height: 50px;  
  max-height: 90px; 
  padding-right: 100px;
}

/* Styles for the scrollbar track (part the thumb slides within) */
.footer-textarea::-webkit-scrollbar-track {
  background: #f1f1f1; /* Light grey track */
  border-radius: 10px;  /* Rounded corners of the track */
}

/* Styles for the scrollbar thumb (part that can be dragged) */
.footer-textarea::-webkit-scrollbar-thumb {
  background-color: #888; /* Darker grey scroll thumb */
  border-radius: 10px; /* Rounded corners of the thumb */
  border: 3px solid #f1f1f1; /* Creates padding around the scroll thumb */
}

/* Hover styles for the scrollbar thumb */
.footer-textarea::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker grey on hover */
}

/* Styles for the scrollbar itself (including buttons, thumb, and track) */
.footer-textarea::-webkit-scrollbar {
  width: 8px; /* Width of the vertical scrollbar */
  height: 8px; /* Height of the horizontal scrollbar (if applicable) */
}

.footer-button {
  position: absolute;
  right: 10px;
  bottom: 13px;
  width: 48px;
  height: 48px;
  border: none;
  border-radius: 50%;
  background-color: black; /* Default active color */
  color: white;
  font-size: 17px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.footer-button svg {
  font-size: 2.0rem;
  color: white;
}

.footer-button:hover {
  background-color: #555; /* Darken on hover */
}

.footer-button:disabled {
  background-color: #cccccc; /* Gray when disabled */
  cursor: not-allowed;
}



.error-message {
  color: red;
  margin-top: 10px;
}

.timestamp {
  font-size: 10px;
  color: #888;
  font-weight: 300;
  font-family: 'San Francisco', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

/* Responsive table wrapper */
.responsive-table {
  overflow-x: auto; /* Enables horizontal scrolling */
  margin: 10px 0; /* Adds some vertical spacing around the table */
}

/* Styles for the table within the responsive wrapper */
.responsive-table table {
  width: 100%; /* Full-width tables */
  border-collapse: collapse; /* Collapses borders between cells */
  border-spacing: 0; /* Removes space between cells */
}

.responsive-table th,
.responsive-table td {
  border: 1px solid #ccc; /* Light grey border for readability */
  padding: 8px; /* Ample padding to make data easily readable */
  text-align: left; /* Aligns text to the left */
}

.responsive-table th {
  background-color: #f2f2f2; /* Light grey background for headers */
}



/* Media queries for mobile devices */
@media only screen and (max-width: 600px) {
  .chat-header {
    height: 45px; /* Reduce header height on mobile */
    padding: 5px; /* Reduce padding for mobile */
  }

  .chat-header h1 {
    font-size: 1.4rem; /* Smaller header text for mobile */
    top: 10px; /* Adjust top positioning */
  }

  .chat-header img {
    height: 40px; /* Reduce logo size */
    top: 5px; /* Adjust top positioning */
  }

  .chat-main {
    padding: 10px;
    margin-top: 50px; /* Ensure messages start after fixed header */
    margin-bottom: 120px; /* Ensure messages end before fixed footer */
    padding-bottom: 100px;
  }

  .profile img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-bottom: 5px;
  }
  .chat-message {

    max-width: 85%; /* More width for messages on mobile */
    margin-left: 4px;
    margin-right: 0;
  }

  .chat-footer {
    padding-bottom: 5px;
  }

  .footer-textarea {
    font-size: 18px;
    height: 40px; /* Adjust input height for mobile */
    padding-right: 80px;
  }

  .footer-button {
    width: 40px; /* Slightly smaller on mobile */
    height: 40px; /* Ensure height and width are the same */
    right: 15px;
    bottom: 20px;
  }

  .footer-button svg {
    font-size: 1.5rem; /* Adjust icon size for smaller button */
  }
  .disclaimer {
    font-size: 0.7rem;
    margin-top: 3px;
  }
}


.disclaimer {
  text-align: center;
  margin-top: 5px;
  font-size: 0.8rem;
  color: gray;
}